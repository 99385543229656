//services/cognitoConfig.ts

import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_jJGfWPV2e',
    ClientId: '4engrpop64g6r19qr3ssl0o662'
};

export const userPool = new CognitoUserPool(poolData);
