//utils/biblio-for-react-authorized.ts

import axios from 'axios';

const API_URL = 'https://5ksv41zhte.execute-api.us-east-1.amazonaws.com/default/biblio-for-react-authorized';

export const biblio_for_react_authorized_test = async (globalBookID: string, idToken? :string) => {
    console.log('biblio_for_react_authorized_test');
    
    const data: any = {
        Operation: 'biblio-for-react-authorized-test',
        GlobalBookID: globalBookID,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = idToken;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            console.error('Failed to fetch data:', error.message);
        } else {
            console.error('Failed to fetch data:', error);
        }
        return null;
    }
};
