//context/AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import { User } from '../types/types';
import { userPool } from '../services/cognitoConfig';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

interface AuthContextProps {
    isLoggedIn: boolean;
    login: (userInfo: User) => void;
    logout: () => void;
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    userSub: string | null;
    idToken: string | null;
    refreshIdToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(() => {
        const storedUser = localStorage.getItem("userInfo");
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [userSub, setUserSub] = useState<string | null>(null);
    const [idToken, setIdToken] = useState<string | null>(null);

    const refreshIdToken = useCallback(async (): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                        console.error('Error refreshing token:', err);
                        reject(err);
                        return;
                    }
                    if (session && session.isValid()) {
                        const newIdToken = session.getIdToken().getJwtToken();
                        setIdToken(newIdToken);
                        setUserSub(session.getIdToken().payload.sub);
                        resolve(newIdToken);
                    } else {
                        console.warn('Session is invalid');
                        resolve(null);
                    }
                });
            } else {
                console.warn('No current user');
                resolve(null);
            }
        });
    }, []);

    useEffect(() => {
        refreshIdToken();
    }, [refreshIdToken]);

    const isLoggedIn = Boolean(user && idToken);

    const login = useCallback((userInfo: User) => {
        console.log("Logged in");
        setUser(userInfo);
        setUserSub(userInfo.sub);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        refreshIdToken();
    }, [refreshIdToken]);

    const logout = useCallback(() => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }

        localStorage.removeItem("userInfo");
        setUser(null);
        setIdToken(null);
        setUserSub(null);
    }, []);

    const contextValue: AuthContextProps = {
        isLoggedIn,
        user,
        setUser,
        login,
        logout,
        userSub,
        idToken,
        refreshIdToken,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};